'use client';

import React from 'react';
import { motion } from 'framer-motion';

const icon = {
  hidden: {
    pathLength: 0,
  },
  visible: {
    pathLength: 1,
    strokeWidth: '2.30894',
    transition: {
      default: { duration: 1, ease: 'easeInOut' },
      duration: 1,
    },
  },
};

const rects = {
  hidden: {
    x: 11,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.3,
      duration: 0.4,
    },
  },
};

const rects2 = {
  hidden: {
    x: 10,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 0.3,
    },
  },
};

const rects3 = {
  hidden: {
    x: 9,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,

    transition: {
      delay: 0.8,
      duration: 0.2,
    },
  },
};

export function Logo() {
  return (
    <>
      <motion.div style={{ height: '35px', width: '35px' }}>
        <svg
          width='35'
          height='35'
          viewBox='0 0 32 21'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <motion.path
            variants={icon}
            initial='hidden'
            animate='visible'
            d='M30.1263 10.2076L13.5681 2.00193L12.9755 1.72655L12.3828 1.45117L13.0018 3.05145L16.0747 10.2076M30.1263 10.2076L12.3828 19.5303L16.0747 10.2076M30.1263 10.2076H16.0747'
            stroke='#264EC7'
          />
          <motion.rect
            variants={rects}
            initial='hidden'
            animate='visible'
            x='0.158691'
            y='5.81348'
            width='9.42743'
            height='2.01377'
            rx='1.00689'
            fill='#FCA311'
          />
          <motion.rect
            variants={rects2}
            initial='hidden'
            animate='visible'
            x='2.51611'
            y='9.36426'
            width='7.07057'
            height='2.01377'
            rx='1.00689'
            fill='#FCA311'
          />
          <motion.rect
            variants={rects3}
            initial='hidden'
            animate='visible'
            x='5.02197'
            y='12.9141'
            width='4.56455'
            height='2.01377'
            rx='1.00689'
            fill='#FCA311'
          />
        </svg>
      </motion.div>
    </>
  );
}
