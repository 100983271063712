'use client';

import Script from 'next/script';

export function GtagEvent() {
  return (
    <>
      <Script
        id='gtagevent'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
           function gtagSendEvent(url) {
              var callback = function () {
                if (typeof url === 'string') {
                  window.location = url;
                }
              };
              gtag('event', 'manual_conversion_SUBMIT_LEAD_FORM', {
                'event_callback': callback,
                'event_timeout': 2000,
              });
              return false;
            }
      `,
        }}
      />
    </>
  );
}