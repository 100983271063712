'use client';

import { Service } from '@/types';


type Props = {
  services: Array<Service>
  callback: (navigator: { term: string, Url: string }) => void;
}

export function Autocomplete({ services, callback }: Props) {
  return (
    <>
      <ul>
        {
          services.map(({ Service, Url }, i) => {
            return (
              <li
                onClick={() => callback({ term: Service, Url: Url })}
                className={'cursor-pointer min-w-[697px] flex items-center px-[23px] h-[43px] border-b-[1px] border-bar hover:bg-bar'}
                key={i}
              >
                {Service}
              </li>
            );

          })
        }
      </ul>

    </>
  );
}