import React, { PropsWithChildren, CSSProperties, forwardRef} from 'react';
import tw from 'tailwind-styled-components';

interface InputProps extends React.HTMLProps<HTMLInputElement> {
    id: string;
    type: string;
    name?: string;
    value?: string | number | undefined;
    placeholder: string;
    className?: string;
    style?: CSSProperties;
    icon?: string;
    required?: boolean;
}

const InputContainer = tw.div`
    relative
`;

const IconContainer = tw.span`
  absolute
  left-3
  mt-5
  flex
  items-center
`;

const CustomInput = tw.input`
  focus:shadow-outline
  leading-tight
  rounded
  pl-10
  focus:outline-none
  placeholder:text-[#565656]
`;


// eslint-disable-next-line react/display-name
export const Input = forwardRef<HTMLInputElement, PropsWithChildren<InputProps>>(({children, icon, ...props}, ref) => (
    <InputContainer>
        {icon && <IconContainer><i className={"mdi icons-edit " + icon}/></IconContainer>}
        <CustomInput autoComplete="new-password" ref={ref} {...props}>{children}</CustomInput>
    </InputContainer>
));
