import tw from 'tailwind-styled-components';

interface Props {
  notFound: boolean;
}

const Alert = tw.span`
text-[#FF4B4B]
text-sm
font-bold
`;

export default function NotFound({ notFound }: Props) {
  if (!notFound) return null;
  return (
    <>
      <div className='m-2'>
        <Alert>Ainda não temos esse serviço disponível em nosso site</Alert>
      </div>
    </>
  );
}
