import React, { useState } from 'react';
import { PrimaryServices, SecondaryServices } from '@/types';
import Link from 'next/link';

interface Props {
  services: Array<PrimaryServices>;
}

export function Submenu({ services }: Props) {
  const [activeServiceIndex, setActiveServiceIndex] = useState<number | null>(null);

  const handleMouseEnter = (index: number) => {
    setActiveServiceIndex(index);
  };

  return (
    <>
      <ul className='List'>
        {services.map((service: PrimaryServices, i: number) => (
          <Link key={i} href={{ pathname: `/${services[i].SlugName}` }}>
            <SubmenuItem
              key={i}
              service={service}
              isActive={i === activeServiceIndex}
              onMouseEnter={() => handleMouseEnter(i)}
            />
          </Link>
        ))}
      </ul>
    </>
  );
}

type SubmenuItemProps = {
  service: PrimaryServices;
  isActive: boolean;
  onMouseEnter: () => void;
};

const SubmenuItem = ({ service, isActive, onMouseEnter }: SubmenuItemProps) => {
  return (
    <>
      <li>
        <div
          onMouseEnter={onMouseEnter}
          className={`ListItemLink ${isActive ? 'ListItemLink__active' : ''}`}>
          {service.Name}
        </div>
        {service.SecondaryServices && (
          <ul
            data-state={`${isActive ? 'open' : 'closed'}`}
            className={`${isActive ? 'table' : 'hidden'} shadow-md Submenu`}>
            {service.SecondaryServices.map((secondaryService: SecondaryServices, i: number) => (
              <li key={i}>
                <Link href={{ pathname: `/${service.SlugName}/${secondaryService.SlugName}` }}>
                  <span> {secondaryService.Name} </span>
                </Link>
              </li>
            ))}
          </ul>
        )}
      </li>
    </>
  );
};