'use client';

import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';

type LocalizacaoType = {
  cidadeLocation: string
  estadoLocation: string
}

const LocalizacaoEmTempoRealContext = createContext({} as LocalizacaoType);

type Props = {
  children: ReactNode
}

const API_URL = 'https://api.bigdatacloud.net/data/reverse-geocode-client';


export const LocalizacaoProvider = ({ children }: Props) => {
  const [cidadeLocation, setCidade] = useState('');
  const [estadoLocation, setEstado] = useState('');

  useEffect(() => {
    if ('geolocation' in navigator) {
      const watchId = navigator.geolocation.watchPosition(
        async (position) => {
          const locationData: any = await getLocation();
          if (locationData) {
            const { city, principalSubdivision } = locationData;
            setCidade(city);
            setEstado(principalSubdivision);
          }
        },
        (error) => {
          console.log('Erro ao pegar a localização do usuário', error);
        },
      );

      return () => {
        navigator.geolocation.clearWatch(watchId);
      };
    }
  }, []);


  const getLocation = async (): Promise<any> => {
    try {
      const position: any = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      const response = await fetch(`${API_URL}?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}&localityLanguage=en`);
      const data = await response.json();

      return {
        city: data.city,
        principalSubdivision: data.principalSubdivision,
      };

    } catch (error) {
      console.error('Erro ao obter dados da API', error);
    }
  };

  return (
    <LocalizacaoEmTempoRealContext.Provider value={{ cidadeLocation, estadoLocation }}>
      {children}
    </LocalizacaoEmTempoRealContext.Provider>
  );
};

export const Location = () => useContext(LocalizacaoEmTempoRealContext);