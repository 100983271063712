'use client';

import React, { useState, useEffect } from 'react';
import { getLocalStorage, setLocalStorage } from '@/utils/storage';

import tw from 'tailwind-styled-components';

const PopupContainer = tw.div`
  fixed
  md:bottom-5
  bottom-10
  mx-6
  md:mx-0
  w-25
  md:right-20
  p-5
  bg-[white]
  rounded-lg
  shadow-md
  md:w-96
  flex
  flex-col
  items-center
  card
`;

const Content = tw.div`
  text-center
  md:px-10
  px-2
`;

const Link = tw.a`
  text-blue-700
  underline
`;

const Button = tw.button`
  transition
  px-10 md:py-2
  text-lg
  mt-2
  md:mt-10
  bg-primary 
  text-[white] 
  rounded-full
  border-2
  hover:border-primary
  hover:text-primary
`;

export default function CookiePopup() {
  const [cookieConsent, setCookieConsent] = useState(false);
  useEffect(() => {
    const storedCookieConsent = getLocalStorage('cookie_consent', null);

    setCookieConsent(storedCookieConsent);
  }, [setCookieConsent]);

  useEffect(() => {
    const newValue = cookieConsent ? 'granted' : 'denied';

    if (window && window.gtag) {
      window.gtag('consent', 'update', {
        'analytics_storage': newValue,
      });
    }

    setLocalStorage('cookie_consent', cookieConsent);

    console.log('Cookie Consent: ', cookieConsent);

  }, [cookieConsent]);

  return (
    <>
      <div className={`${cookieConsent != null ? 'hidden' : 'flex'}`}>
        <PopupContainer>
          <Content>
            <p>
              Utilizamos cookies para oferecer a melhor experiência de navegação e segurança.&nbsp;
              <Link className={'text-primary'} href='/termos-e-politicas'>Saiba mais aqui!</Link>
            </p>
          </Content>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Button onClick={() => setCookieConsent(true)}>OK</Button>
            <Button className='bg-0 text-primary' onClick={() => setCookieConsent(false)}>Rejeitar</Button>
          </div>
        </PopupContainer>
      </div>
    </>
  );
}