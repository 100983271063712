'use client';

import { ReactNode } from 'react';
import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { LocalizacaoProvider } from '@/context/location';

type Props = {
  children: ReactNode;
};

export function Providers({ children }: Props) {
  const secret: string = '6LcRA-0nAAAAAJSSZH2kPGTWPH9quLDJOzSs-KpV';

  return (
    <>
      <LocalizacaoProvider>
        <GoogleReCaptchaProvider
          reCaptchaKey={secret}
          scriptProps={{ defer: true }}
        >
          {children}
          <ProgressBar
            height='5px'
            color='#264EC7'
            options={{ showSpinner: false }}
            shallowRouting
          />
        </GoogleReCaptchaProvider>
      </LocalizacaoProvider>
    </>
  );
}
