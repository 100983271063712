import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {
  ArrowRightIcon,
  CaretDownIcon,
} from '@radix-ui/react-icons';
import './styles/dropdown.scss';
import { Category } from '@/types';
import Link from 'next/link';

interface Props {
  categories: Array<Category>;
}

const DropdownMenuDemo = ({ categories }: Props) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild className="min-h-[52px]">
        <button className='IconButton' aria-label='Customise options'>
          Ver demais categorias
          <CaretDownIcon />
        </button>
      </DropdownMenu.Trigger>

      {categories && (
        <DropdownMenu.Portal>
          <DropdownMenu.Content className='DropdownMenuContent' sideOffset={5}>
            {categories.slice(6).map((category, i: number) => (
              <DropdownMenu.Sub key={i}>
                <DropdownMenu.SubTrigger className='DropdownMenuSubTrigger'>
                  <div className='squareMarker' />
                  <span>{category.Name}</span>
                  <div className='RightSlot'>
                    <ArrowRightIcon />
                  </div>
                </DropdownMenu.SubTrigger>

                <DropdownMenu.Portal>
                  <DropdownMenu.SubContent
                    className='DropdownMenuSubContent'
                    sideOffset={-2}
                    alignOffset={-5}
                  >
                    {category.PrimaryServices?.map((primaryServices, k: number) => (
                      <DropdownMenu.Sub key={k}>
                        <DropdownMenu.SubTrigger className='DropdownMenuSubTrigger'>
                          <div className='squareMarker' />
                          <Link href={{ pathname: `/${primaryServices.SlugName}` }}>
                            <span>{primaryServices.Name}</span>
                          </Link>
                          <div className='RightSlot'>
                            <ArrowRightIcon />
                          </div>
                        </DropdownMenu.SubTrigger>

                        <DropdownMenu.Portal>
                          <DropdownMenu.SubContent
                            className='DropdownMenuSubContent'
                            sideOffset={-2}
                            alignOffset={-5}
                          >
                            {primaryServices.SecondaryServices?.map((secondaryServices, l: number) => (
                              <DropdownMenu.Item key={l} className='DropdownMenuItem'>
                                <Link href={{
                                  pathname: `/${primaryServices.SlugName}/${secondaryServices.SlugName}`,
                                }}>
                                  <span>{secondaryServices.Name}</span>
                                </Link>
                              </DropdownMenu.Item>
                            ))}
                          </DropdownMenu.SubContent>
                        </DropdownMenu.Portal>
                      </DropdownMenu.Sub>
                    ))}
                  </DropdownMenu.SubContent>
                </DropdownMenu.Portal>
              </DropdownMenu.Sub>
            ))}
            <DropdownMenu.Arrow className='DropdownMenuArrow' />
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      )}
    </DropdownMenu.Root>

  );
};

export default DropdownMenuDemo;
